import React, {useState} from 'react'
import axios from 'axios';
import './slack.css';
import logo from '../assets/logo.png'

const getErrorMessage = (error) => error.status === 404 || error.status === 500
  ? "Błąd komunikacji z serwerem" : 
  error.data

const Error = ({error}) => <p>
  ⚠️ {getErrorMessage(error)}.
  Prosimy o kontakt z nami: <a href="mailto:kontakt@graosens.org.pl">kontakt@graosens.org.pl</a>
</p>

const SlackInvite = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState({sending: false, message: undefined, send: false});
    return (<main className={status.send ? "send": ""}>
        <div class="aside-section aside-left">
        <div class="aside-content">
          <a href="https://www.graosens.org.pl/"><img src={logo} alt="Gra o Sens" class="brand"/></a>
          <div class="message">
            <h3>Dziękujemy!</h3>
            <p>Na podany przez Ciebie adres email wysłaliśmy zaproszenie do <a href="https://graosens.slack.com/">Slacka</a>.</p>
          </div>
        </div>
      </div>
      <div class="aside-section aside-right">
        <div class="aside-content">
          <div class="social-network">
            <a href="https://graosens.slack.com/"><i title="Slack" class="fa fa-slack"></i></a>
          </div>
          <form>
            <h3>Rejestracja</h3>
            <input
                name="email"
                id="email"
                placeholder="Podaj adres email"
                aria-label="email"
                aria-describedby="email"
                value={email}
                type="email"
                onChange={({target: {value}}) => setEmail(value)}
            />
            <input
              type="submit"
              class="btn btn-secondary"
              role="button"
              value="Dołącz"
              onClick={() => {
                    setStatus({sending: true, message: undefined}); 
                    axios
                        .get(`/api/slack-invite?email=${email}`)
                        .then(response => setStatus({
                          sending: false,
                          send: response.status===200}))  
                        .catch(({response}) => setStatus({
                          sending: false,
                          message: <Error error={response}/>
                        }));

                }}
              disabled={status.sending}
            />
            {status.message}
            <p>Dziękujemy, że chcesz dołączyć do naszej społeczności. Dzięki <a href="https://graosens.slack.com/">Slackowi</a> będziesz z&nbsp;nami w&nbsp;stałym kontakcie.</p>
          </form>
        </div>
      </div>
      <div class="about">
        <p>
          Dowiedz się co robimy i jak działamy!
          <a
            href="https://www.graosens.org.pl/dzieje-sie.htm"
            class="btn btn-secondary"
            role="button"
          >Sprawdź</a>
        </p>
      </div>
      </main>
    )
}

export default SlackInvite;
