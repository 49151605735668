import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import SlackInvite from "./SlackInvite";

const App = () =>
    <Router>
        <Switch>
            <Route path="/slack-invite">
                <SlackInvite/>
            </Route>
            <Route path="/">
                <SlackInvite/>
            </Route>
        </Switch>
    </Router>;


export default App;
